import { OrganizationService } from '@app/services/organization.service';
import { Injectable } from "@angular/core";
import { AuthenService } from "@app/services/authen.service";
import { IOrganization } from "@app/types/IOrganization"
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, createReducer, on, Store } from "@ngrx/store"
import { mergeMap, lastValueFrom } from 'rxjs';



// State Type
export const profileOrgActions = {
    loadProfile: createAction('[profileOrgActions][Profile] Load Profile'),
    setProfile: createAction('[profileOrgActions][Profile] Set Profile', (profile: IOrganization.ProfileResponse) => ({ profile })),
}

export const profileOrgState: IOrganization.ProfileResponse = null
// {
//     percent: 0,
//     profile: {
//         address: "",
//         category_industry_id: null,
//         consent_terms_privacy_policy: null,
//         created_at: "",
//         created_by: "",
//         description: "",
//         district: "",
//         email: "",
//         logo: "",
//         id: null,
//         latitude: "",
//         longitude: "",
//         map_zoom: "",
//         name: "",
//         province_id: null,
//         status: null,
//         subdistrict: "",
//         tel: "",
//         updated_at: "",
//         updated_by: "",
//         username: "",
//         zipcode: "",
//         province: [] as any,
//         industry: [] as any,
//         banners: []
//     }
// }


const _profileOrgReducer = createReducer(
    profileOrgState,
    on(profileOrgActions.setProfile, (state, { profile }) => ({ ...state, ...profile })),
);

export function profileOrgReducer(state: any, action: any) {
  return _profileOrgReducer(state, action);
}

@Injectable()
export class ProfileOrgEffects {
    constructor(
        private actions$: Actions,
        private authen: AuthenService,
        private store: Store<any>,
        private organizationService: OrganizationService
    ) {}

    // load profile
    loadProfile$ = createEffect(
        () => this.actions$.pipe(
            ofType(profileOrgActions.loadProfile),
            mergeMap(async () => {
                let profile = await lastValueFrom(this.organizationService.getProfile())
                this.store.dispatch(profileOrgActions.setProfile(profile))
            })
        ),
        { dispatch: false }
    )

}