import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IOrganization } from '@app/types/IOrganization';
import { Observable } from 'rxjs';
import Helper from './helper';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {

  constructor(
    private http: HttpClient
  ) { }

  getProfile(): Observable<IOrganization.ProfileResponse>{
    return this.http.get('/organization/profile') as Observable<IOrganization.ProfileResponse>
  }

  saveProfile(profile: IOrganization.Profile): Observable<IOrganization.ProfileResponse>{
    return this.http.put('/organization/profile', profile) as Observable<IOrganization.ProfileResponse>
  }

  updatePassword(password: IOrganization.PasswordInput): Observable<IOrganization.ProfileResponse>{
    return this.http.put('/organization/password', password) as Observable<IOrganization.ProfileResponse>
  }

  search(model: IOrganization.SearchInput ): Observable<IOrganization.SearchResponse>{
    return this.http.post('/organization/search', model) as Observable<IOrganization.SearchResponse>
  }

  getById(id: any): Observable<IOrganization.ProfileResponse>{
    return this.http.get(`/organization/byid/${id}`) as Observable<IOrganization.ProfileResponse>
  }

  toggleEmail(): Observable<{
    res_code: number;
    message: string;
    email_notification: number
  }> {
    return this.http.put(`/organization/email-notification/toggle`, {}) as Observable<any>
  }

}
