<header class="header-luihub d-none d-lg-block">
    <div class="container-lg">
        <div class="row justify-content-between">
            <div class="col-auto">
                <img src="/assets/images/logo-luihub-primary.svg" width="64">
            </div>
            <div class="col-auto align-self-center">
                <ul class="navbar-nav">
                    <li class="nav-item" routerLink="/home" routerLinkActive="active">
                        <a class="nav-link">
                            <ion-icon name="home-outline" class="font-tmt-18 me-1" routerLinkActive="d-none"></ion-icon>
                            <ion-icon name="home" class="font-tmt-18 unactive me-1" routerLinkActive="d-block"></ion-icon>
                            หน้าหลัก
                        </a>
                    </li>
                    <li class="nav-item" routerLink="/my-jobs" routerLinkActive="active" *ngIf="role == 'organization'">
                        <a class="nav-link">
                            <ion-icon name="megaphone-outline" class="font-tmt-18 me-1" routerLinkActive="d-none"></ion-icon>
                            <ion-icon name="megaphone" class="font-tmt-18 unactive me-1" routerLinkActive="d-block"></ion-icon>
                            ประกาศของฉัน
                        </a>
                    </li>
                    <li class="nav-item" [routerLink]="'/results'" [queryParams]="role == 'worker'?null:{'type':'3'}" routerLinkActive="active">
                        <a class="nav-link">
                            <ion-icon name="search-outline" class="font-tmt-18 me-1" routerLinkActive="d-none"></ion-icon>
                            <ion-icon name="search" class="font-tmt-18 unactive me-1" routerLinkActive="d-block"></ion-icon>
                            ค้นหา
                        </a>
                    </li>
                    <li class="nav-item" routerLink="/wishlists" routerLinkActive="active">
                        <a class="nav-link">
                            <ion-icon name="bookmark-outline" class="font-tmt-18 me-1" routerLinkActive="d-none"></ion-icon>
                            <ion-icon name="bookmark" class="font-tmt-18 unactive me-1" routerLinkActive="d-block"></ion-icon>
                            {{role == 'worker'? 'งานที่สนใจ':'ผู้สมัครที่สนใจ'}}
                        </a>
                    </li>
                    <li class="nav-item" routerLink="/booking" routerLinkActive="active">
                        <a class="nav-link">
                            <ion-icon name="people-outline" class="font-tmt-18 me-1" routerLinkActive="d-none"></ion-icon>
                            <ion-icon name="people" class="font-tmt-18 unactive me-1" routerLinkActive="d-block"></ion-icon>
                            คำร้องขอสมัครงาน
                        </a>
                    </li>
                    <li class="nav-item" routerLink="/notifications" routerLinkActive="active" *ngIf="!role">
                        <a class="nav-link">
                            <ion-icon name="notifications-outline" class="font-tmt-20 me-1" [ngClass]="{'bubble': unread_message > 0}" routerLinkActive="d-none"></ion-icon>
                            <ion-icon name="notifications" class="font-tmt-20 unactive me-1" [ngClass]="{'bubble': unread_message > 0}" routerLinkActive="d-block"></ion-icon>
                            แจ้งเตือน
                        </a>
                    </li>
                    <li class="nav-item" routerLink="/notifications" routerLinkActive="active" nz-popover nzType="primary" [nzPopoverTitle]="null" [nzPopoverContent]="notificationPopover" nzPopoverPlacement="bottomRight" *ngIf="role">
                        <a class="nav-link">
                            <ion-icon name="notifications-outline" class="font-tmt-20 me-1" [ngClass]="{'bubble': unread_message > 0}" routerLinkActive="d-none"></ion-icon>
                            <ion-icon name="notifications" class="font-tmt-20 unactive me-1" [ngClass]="{'bubble': unread_message > 0}" routerLinkActive="d-block"></ion-icon>
                            แจ้งเตือน
                        </a>
                    </li>
                    <li class="nav-item" routerLink="/sign-in" routerLinkActive="active" *ngIf="!role">
                        <a class="nav-link">
                            <ion-icon name="person-circle-outline" class="font-tmt-18 me-1" routerLinkActive="d-none"></ion-icon>
                            <ion-icon name="person-circle" class="font-tmt-18 unactive me-1" routerLinkActive="d-block"></ion-icon>
                            เข้าสู่ระบบ
                        </a>
                    </li>
                    <li class="nav-item" routerLink="/profile" routerLinkActive="active" nz-dropdown [nzDropdownMenu]="menu" *ngIf="role">
                        <a class="nav-link">
                            <img [src]="profile.img" onerror="this.onerror=null;this.src='/assets/images/icons/icon-profile-worker.png';" style="width: 24px; height: 24px;" class="me-2 rounded-pill" *ngIf="role == 'worker'">
                            <img [src]="profile.img" onerror="this.onerror=null;this.src='/assets/images/icons/icon-profile-company.png';" style="width: 24px; height: 24px;" class="me-2 rounded-pill" *ngIf="role == 'organization'">
                            <span class="text-truncate d-inline-block" style="max-width: 100px;">{{profile.name || 'โปรไฟล์'}}</span>
                        </a>
                        <nz-dropdown-menu #menu="nzDropdownMenu">
                            <ul nz-menu nzSelectable>
                                <li nz-menu-item routerLink="/profile/password">เปลี่ยนรหัสผ่าน</li>
                                <li nz-menu-item routerLink="/profile">โปรไฟล์</li>
                                <li nz-menu-item (click)="logOut()" nzDanger>ออกจากระบบ</li>
                            </ul>
                        </nz-dropdown-menu>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</header>

<ng-template #notificationPopover>
    <ng-container *ngIf="notifications.length > 0">
        <div class="wrap-noti">
            <div class="card-noti" *ngFor="let obj of notifications;let isLast = last;" [ngClass]="{'unread': !obj.is_read,'mb-0':isLast}" (click)="onClick(obj)">
                <div>
                    {{obj.message}}
                </div>
                <div class="lh-label text-end">แจ้งเตือนเมื่อ {{obj.created_at}}</div>
            </div>
        </div>
        <a nz-button nzType="primary" class="btn-lh-aliceblue w-100 mt-2" routerLink="/notifications">ดูทั้งหมด</a>
    </ng-container>
    <ng-container *ngIf="!notifications.length">
        <div class="wrap-noti d-flex">
            <div class="my-3 mx-auto text-center">
                <i nz-icon nzType="bell" nzTheme="outline" class="text-black-25 font-tmt-24 mb-2"></i>
                <div class="lh-label">
                    ไม่มีแจ้งเตือน
                </div>   
            </div>
        </div>
    </ng-container>
</ng-template>