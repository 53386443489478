import { AppLayoutComponent } from './app-layout/app-layout.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenGuard } from './guards/authen/authen.guard';

const routes: Routes = [
  {
    path: '', redirectTo: 'home', pathMatch: "full",
  },
  { path: 'sign-up', loadChildren: () => import('@app/sign-up/sign-up.module').then(m => m.SignUpModule) },
  { path: 'sign-in', loadChildren: () => import('@app/sign-in/sign-in.module').then(m => m.SignInModule) },
  {
    canActivate: [AuthenGuard], canActivateChild: [AuthenGuard],
    path: 'home',
    component: AppLayoutComponent,
    children: [
      { path: '', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
    ]
  },
  {
    canActivate: [AuthenGuard], canActivateChild: [AuthenGuard],
    path: 'profile',
    component: AppLayoutComponent,
    children: [
      { path: '', loadChildren: () => import('@app/profile/profile.module').then(m => m.ProfileModule) },
    ]
  },
  { canActivate: [AuthenGuard], canActivateChild: [AuthenGuard], path: 'profile/edit', loadChildren: () => import('@app/edit-profile/edit-profile.module').then(m => m.EditProfileModule) },
  { canActivate: [AuthenGuard], canActivateChild: [AuthenGuard], path: 'profile/education', loadChildren: () => import('@app/setting-education/setting-education.module').then(m => m.SettingEducationModule) },
  { canActivate: [AuthenGuard], canActivateChild: [AuthenGuard], path: 'profile/position', loadChildren: () => import('@app/setting-position/setting-position.module').then(m => m.SettingPositionModule) },
  { canActivate: [AuthenGuard], canActivateChild: [AuthenGuard], path: 'profile/experience', loadChildren: () => import('@app/setting-experience/setting-experience.module').then(m => m.SettingExperienceModule) },
  { canActivate: [AuthenGuard], canActivateChild: [AuthenGuard], path: 'profile/password', loadChildren: () => import('@app/setting-password/setting-password.module').then(m => m.SettingPasswordModule) },
  { canActivate: [AuthenGuard], canActivateChild: [AuthenGuard], path: 'profile/banner', loadChildren: () => import('@app/setting-banner/setting-banner.module').then(m => m.SettingBannerModule) },
  { 
    canActivate: [AuthenGuard], 
    canActivateChild: [AuthenGuard], 
    path: 'results',
    children: [
      { path: '', loadChildren: () => import('@app/search-results/search-results.module').then(m => m.SearchResultsModule) },
    ]
  },
  { canActivate: [AuthenGuard], canActivateChild: [AuthenGuard], path: 'jobseeker/:id', loadChildren: () => import('@app/jobseeker-detail/jobseeker-detail.module').then(m => m.JobseekerDetailModule) },
  { canActivate: [AuthenGuard], canActivateChild: [AuthenGuard], path: 'employer/:id', loadChildren: () => import('@app/employer-detail/employer-detail.module').then(m => m.EmployerDetailModule) },
  // {
  //   canActivate: [AuthenGuard], canActivateChild: [AuthenGuard],
  //   path: 'job',
  //   component: AppLayoutComponent
  // },
  { canActivate: [AuthenGuard], canActivateChild: [AuthenGuard], path: 'job/detail/:id', loadChildren: () => import('@app/job-detail/job-detail.module').then(m => m.JobDetailModule) },
  { canActivate: [AuthenGuard], canActivateChild: [AuthenGuard], path: 'job/create', loadChildren: () => import('@app/job-model/job-model.module').then(m => m.JobModelModule) },
  { canActivate: [AuthenGuard], canActivateChild: [AuthenGuard], path: 'job/edit', loadChildren: () => import('@app/job-model/job-model.module').then(m => m.JobModelModule) },
  { canActivate: [AuthenGuard], canActivateChild: [AuthenGuard], path: 'my-jobs', loadChildren: () => import('@app/my-jobs/my-jobs.module').then(m => m.MyJobsModule) },
  {
    canActivate: [AuthenGuard], canActivateChild: [AuthenGuard],
    path: 'booking',
    component: AppLayoutComponent,
    children: [
      {  path: '', loadChildren: () => import('@app/booking/booking.module').then(m => m.BookingModule) },
    ]
  },
  {  canActivate: [AuthenGuard], canActivateChild: [AuthenGuard], path: 'booking/detail/:schedule_no', loadChildren: () => import('@app/booking-detail/booking-detail.module').then(m => m.BookingDetailModule) },
  {
    canActivate: [AuthenGuard], canActivateChild: [AuthenGuard],
    path: 'wishlists',
    component: AppLayoutComponent,
    children: [
      {  path: '', loadChildren: () => import('./wishlists/wishlists.module').then(m => m.WishlistsModule) }
    ]
  },
  {
    canActivate: [AuthenGuard], canActivateChild: [AuthenGuard],
    path: 'notifications',
    component: AppLayoutComponent,
    children: [
      {  path: '', loadChildren: () => import('./notifications/notifications.module').then(m => m.NotificationsModule) }
    ]
  },
  { path: 'reset-password', loadChildren: () => import('@app/reset-password/reset-password.module').then(m => m.ResetPasswordModule) },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
