<ion-content>
    <section class="lh-content">
        <div class="container-lg">
            <div class="row justify-content-center">
                <div class="col-auto">
                    <img src="/assets/images/logo-luihub-primary.svg" width="150">
                </div>

                <div class="col-12 mt-3 text-center">
                    <h2 class="lh-head mb-0">เปลี่ยนรหัสผ่าน</h2>
                </div>
            
                <div class="col-md-6 col-12 mt-3">
                    <div class="lh-label mb-1">รหัสผ่านใหม่</div>
                    <nz-input-group [nzSuffix]="suffixIconShowPassword">
                        <input nz-input class="w-100" [type]="show_password? 'text':'password'" autocomplete="false" nzSize="large" [(ngModel)]="password_model.password" (ngModelChange)="onInputPassword()">
                    </nz-input-group>
                </div>

                <div class="w-100"></div>

                <div class="col-md-6 col-12 mt-3">
                    <div class="lh-label mb-1">ยืนยันรหัสผ่านอีกครั้ง</div>
                    <nz-input-group [nzSuffix]="suffixIconShowConfirmPassword">
                        <input nz-input class="w-100" [type]="show_confirm_password? 'text':'password'" autocomplete="false" nzSize="large" [(ngModel)]="password_model.cf_password" (ngModelChange)="onInputPassword()">
                    </nz-input-group>
                </div>

                <div class="w-100"></div>
                
                <div class="col-md-6 col-12 mt-1" *ngIf="error.visible">
                    <div class="ant-form-item-explain-warning font-weight-bold">
                        <i nz-icon nzType="exclamation-circle" nzTheme="fill"  class="v-middle"></i> {{error.message}}
                    </div>
                </div>

                <div class="w-100"></div>

                <div class="col-md-6 col-12 mt-3">
                    <button nz-button nzType="primary" nzSize="large" class="w-100 btn-lh-primary" (click)="resetPassword()" [disabled]="error.visible">
                        <span>ยืนยันเปลี่ยนรหัสผ่าน</span> 
                    </button>
                </div>

            </div>

        </div>
    </section>
    
</ion-content>

<ng-template #suffixIconShowPassword>
  <i nz-icon [nzType]="!show_password? 'eye':'eye-invisible'" class="alink-text-black-85" (click)="showPassword(show_password,'show_password')"></i>
</ng-template>
<ng-template #suffixIconShowConfirmPassword>
  <i nz-icon [nzType]="!show_confirm_password? 'eye':'eye-invisible'" class="alink-text-black-85" (click)="showPassword(show_confirm_password,'show_confirm_password')"></i>
</ng-template>