import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { INotification } from '@app/types/INotification';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    private http: HttpClient
  ) { }

  getNotification(): Observable<INotification.Notification[]>{
    return this.http.get('/notification/notification') as Observable<INotification.Notification[]>
  }

  unnotification(id: any){
    return this.http.delete('/notification/unnotification/'+id) as Observable<any>
  }

  unnotifications(model: { ids: number[] }){
    return this.http.post('/notification/unnotification-delete', model) as Observable<any>
  }

  unnotificationAll(){
    return this.http.delete('/notification/unnotification-all') as Observable<any>
  }

  markAsRead(id: any){
    return this.http.put(`/notification/mark-as-read/${id}`,{}) as Observable<any>
  }

}
