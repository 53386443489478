import { appActionsReducer } from "./app_actions";
import { ProfileOrgEffects, profileOrgReducer } from "./org_profile";
import { searchModelReducer } from "./search_model";
import { WorkerProfileEffects, workerProfileReducer } from "./worker_profile";



export default {
    stores: {
        app_actions: appActionsReducer,
        profile_org: profileOrgReducer,
        profile_worker: workerProfileReducer,
        search_model: searchModelReducer
    },
    effects: [
        ProfileOrgEffects,
        WorkerProfileEffects
    ]
}