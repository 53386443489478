import { IJobs } from '@app/types/IJobs';
import { IOrganization } from '@app/types/IOrganization';
import { IWishlist } from '@app/types/IWishlist';
import { IWorker } from '@app/types/IWorker';
import { createAction, createReducer, on } from "@ngrx/store";

type SearchModel = IJobs.SearchModel | IOrganization.SearchInput | IWishlist.SearchModel | IWorker.SearchModel
type SearchModelName = 'job_model' | 'organization_model' | 'wishlist_model' | 'worker_model'
// State Type
export const searchModelActions = {
    setSearchModel: createAction('[SearchModel] Set SearchModel',(searchModel: SearchModel, modelName: SearchModelName) => {
        return { 
            search_model: { [modelName]: searchModel }
        }
    }),
}

export const searchModelState = {
    job_model: {
        page: {
          page: 1,
          pageSize: 20
        },
        job_tag_ids: [],
        immediately: false,
        chaffer: false,
        province_ids: [],
        search: "",
        start_date: "",
        end_date: "",
        accepting_persons_with_disabilities: false,
        salary_min: 0,
        salary_max: 0,
        salary_range: "",
        track_log: false,
        organization_id: 0,
        is_register_deadline: false,
        all_status: false
    } as IJobs.SearchModel,
    organization_model: {
        page: {
            page: 1,
            pageSize: 20
        },
        org_name: "",
        province_ids: [],
        random: false
    } as IOrganization.SearchInput,
    worker_model: {
        page: {
            page: 1,
            pageSize: 20
        },
        job_tag_ids: [],
        immediately: false,
        experience: -2,
        province_ids: [],
        search: "",
        start_date: "",
        end_date: "",
        track_log: false,
        random: false,
        persons_with_disabilities: false,
        study_program_id: 0
    } as IWorker.SearchModel,
    wishlist_model: {
        page: {
            page: 1,
            pageSize: 20
        },
        search: "",
    } as IWishlist.SearchModel
}

const _searchModelReducer = createReducer(
    searchModelState,
    on(searchModelActions.setSearchModel, (state, { search_model }) => {
        return {
            ...state,
            ...search_model
        }
    })
)

export function searchModelReducer(state: any, action: any) {
  return _searchModelReducer(state, action);
}