import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IAuthorization } from '@app/types/IAuthorization';
import { Observable } from 'rxjs';
import jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class AuthenService {

  constructor(
    private http: HttpClient,
  ) { }

  KEY_NAME = 'authorization'

  getAuthorization(): IAuthorization.ResponseToken{
    let token = localStorage.getItem(this.KEY_NAME)
    if(!token){
      return {
        token_type: 'Bearer',
        access_token: null,
        refresh_token: null,
        expires_in: null,
        is_registered: false
      }
    }
    return JSON.parse(token)
  }

  getAuthenToken(): string{
    return this.getAuthorization().access_token
  }

  getRefreshToken(): string{
    return this.getAuthorization().refresh_token
  }

  refresh(refreshToken: string): Observable<IAuthorization.ResponseToken>{
    return this.http.post('/authen/refresh', {refresh_token: refreshToken}) as Observable<IAuthorization.ResponseToken>
  }

  signUp(data: IAuthorization.SignUpInput): Observable<{
    token_type: 'Bearer'
    access_token: string
    refresh_token: string
    expires_in: number
    is_registered: boolean
  }> {
    return this.http.post(`/authen/sign-up`, data) as  Observable<any>;
  }

  signIn(data: IAuthorization.SignInInput): Observable<IAuthorization.ResponseToken>{
    return this.http.post('/authen/sign-in', data) as Observable<IAuthorization.ResponseToken>
  }

  requestOtp(data: IAuthorization.RequestOTPInput): Observable<{
    res_code: number,
    message: string,
    public_ref: string,
    dev_otp?: string
  }>{
    return this.http.post('/authen/request-otp', data) as Observable<any>;
  }
  
  jwtDecode(access_token: string | null): IAuthorization.AuthenDecode | null {
    if(!access_token) return null
    return jwt_decode(access_token)
  }

  signInSocial(data: IAuthorization.SocialSignInInput): Observable<IAuthorization.ResponseToken>{
    return this.http.post('/authen/social/sign-in', data) as Observable<IAuthorization.ResponseToken>
  }

  forgotPassword(data: {
    email: string,
    type: IAuthorization.RoleType
  }): Observable<any> {
    return this.http.post('/authen/forgot-password', data) as Observable<any>
  }

  verify_password(data: any){
    return this.http.post('/authen/verify-password',data) as Observable<any>
  }

  checkResetPasswordLink(data: { expire_ref: string, type: IAuthorization.RoleType }): Observable<{ status: boolean }>{
    return this.http.get(`/authen/reset-password?expire_ref=${data.expire_ref}&type=${data.type}`) as Observable<{ status: boolean }>
  }

  resetPassword(data: IAuthorization.ResetPasswordInput): Observable<IAuthorization.ResponseToken>{
    return this.http.post('/authen/reset-password',data) as Observable<IAuthorization.ResponseToken>
  }
}
