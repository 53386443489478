// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  host: 'dev.luihub.com',
  production: false,
  hmr: true,
  api_path: 'https://devapi.luihub.com/api/jobs',
  google_recaptcha: {
    version: 'v3',
    site_key: '6LcjYysfAAAAAIzgg4r5Wk_ZkUQYDwttZoIA_Y1I'
  },
  fb_app: {
    id: '416796310277048',
    version: 'v13.0'
  },
  liffId: '1657093577-ynB07Q8O',
  google_login: '466501708131-hk3babuvrbkrfl0jgqcnbtgkh4ujfcds.apps.googleusercontent.com',
  cms_domain: "dev.luihub.com",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
