import { Injectable } from "@angular/core";
import { WorkersService } from "@app/services/workers.service";
import { IWorker } from "@app/types/IWorker";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, createReducer, on, Store } from "@ngrx/store";
import { lastValueFrom, mergeMap } from "rxjs";



export const workerProfileActions = {
    loadProfile: createAction('[workerProfileActions][Profile] Load Profile'),
    setProfile: createAction('[workerProfileActions][Profile] Set Profile', (profile: IWorker.WorkerProfile) => ({ profile })),
}


export const workerProfileState: IWorker.WorkerProfile = null


const _workerProfileReducer = createReducer(
    workerProfileState,
    on(workerProfileActions.setProfile, (state, { profile }) => {
        let updatedProfile = { ...state, ...profile };
        if (!profile.require_info) {
            delete updatedProfile['require_info'];
            delete updatedProfile['invalid_fields'];
        }
        return updatedProfile;
    }),
);

export function workerProfileReducer(state: any, action: any) {
  return _workerProfileReducer(state, action);
}

@Injectable()
export class WorkerProfileEffects {
    constructor(
        private actions$: Actions,
        private store: Store<any>,
        private workersService: WorkersService
    ) {}

    // load profile
    loadProfile$ = createEffect(
        () => this.actions$.pipe(
            ofType(workerProfileActions.loadProfile),
            mergeMap(async () => {
                let profile = await lastValueFrom(this.workersService.getProfile())
                this.store.dispatch(workerProfileActions.setProfile(profile))
            })
        ),
        { dispatch: false }
    )

}