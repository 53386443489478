import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenService } from '@app/services/authen.service';
import Helper from '@app/services/helper';
import { IAuthorization } from '@app/types/IAuthorization';
import { AlertController, LoadingController } from '@ionic/angular';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

declare let grecaptcha: any,liff: any

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  tab = 'worker' as IAuthorization.RoleType
  show_password = false
  show_confirm_password  = false  

  constructor(
    private activatedRoute: ActivatedRoute,
    private authen: AuthenService,
    private alertController: AlertController,
    private loadingController: LoadingController,
  ) { }

  reset_model = {
    type: 'worker',
    expire_ref: '',
    verify_password: '',
    reCAPTCHA: '',
    password: ''
  } as IAuthorization.ResetPasswordInput
  password_model = {
    password: '',
    cf_password: ''
  }
  error = {
    visible: false,
    message: ''
  }

  async ngOnInit() {
    this.reset_model.type = this.activatedRoute.snapshot.queryParamMap.get('type') || 'worker' as any
    this.reset_model.verify_password = this.activatedRoute.snapshot.queryParamMap.get('verify_password') || ''
    this.reset_model.expire_ref = this.activatedRoute.snapshot.queryParamMap.get('expire_ref') || ''
    await this.checkLink()
  }

  async checkLink() {
    let loading = await this.loadingController.create({
      message: 'Please wait...',
      spinner: 'crescent',
      translucent: true,
      cssClass: 'custom-class custom-loading'
    });
    await loading.present();
    try {
      await lastValueFrom(this.authen.checkResetPasswordLink({ expire_ref: this.reset_model.expire_ref, type: this.reset_model.type}))
      await loading.dismiss();
    } catch (err: any) {
      const alert = await this.alertController.create({
        header: 'เกิดข้อผิดพลาด',
        message: err.error.message
      })
      await alert.present()
      await loading.dismiss();
      alert.onDidDismiss().then(() => {
        location.href = "/"
      })  
    }
  }

  async resetPassword() {
    let passCheck = this.validatePassword()
    if (passCheck.error) {
      this.error.message = passCheck.message
      this.error.visible = true
      return
    }
    this.setShowPasswordAll()
    let loading = await this.loadingController.create({
      message: 'Please wait...',
      spinner: 'crescent',
      translucent: true,
      cssClass: 'custom-class custom-loading'
    });
    await loading.present();

    if(!this.password_model.password || this.password_model.password && this.password_model.password.length < 8){
      const alert = await this.alertController.create({
        header: 'เกิดข้อผิดพลาด',
        message: 'รหัสผ่านต้องมีความยาวอย่างน้อย 8 ตัวอักษร',
      })
      await loading.dismiss();
      await alert.present()
      return
    }

    let helper = new Helper()
    if(!helper.passwordPattern(this.password_model.password)){
      let a = await this.alertController.create({
        header: 'ต้องมีอักขระพิเศษ ตัวอักษรภาษาอังกฤษตัวเล็กและตัวใหญ่ และตัวเลข',
        buttons: ['ตกลง']
      })
      await loading.dismiss();
      await a.present();
      return
    }
    try {
      this.reset_model.password = await helper.sha256(this.password_model.password)
      this.reset_model.reCAPTCHA = await grecaptcha.execute(environment.google_recaptcha.site_key, { action: 'reset'})
      let res = await lastValueFrom(this.authen.resetPassword(this.reset_model))
      localStorage.setItem(this.authen.KEY_NAME,JSON.stringify(res))
      await loading.dismiss();
      location.href = '/'
    } catch  (err: any) {
      const alert = await this.alertController.create({
        header: 'เกิดข้อผิดพลาด',
        message: err.error.message,
      })
      await alert.present()
      await loading.dismiss();    
    }
  }

  validatePassword(): {error: boolean; message: string} {
    if (this.password_model.password !== this.password_model.cf_password) {
      return {
        error: true,
        message: 'รหัสผ่านไม่ตรงกัน'
      }
    }
    let charLengthTest = !this.password_model.password || this.password_model.password && this.password_model.password.length < 8
    let speacialCharTest = !/[a-z]/gi.test(this.password_model.password) || !/[0-9]/g.test(this.password_model.password) || !/[!@#$%^&*(),.?":{}|<>]/g.test(this.password_model.password)
    if(charLengthTest || speacialCharTest){
      return {
        error: true,
        message: 'รหัสผ่านต้องมีความยาวอย่างน้อย 8 ตัวอักษร ประกอบด้วยอักขระพิเศษ ตัวอักษรภาษาอังกฤษ และตัวเลข'
      }
    }
    return {
      error: false,
      message: ''
    }
  }

  onInputPassword() {
    if (this.error.visible) {
      this.error.visible = false
    }
  }

  showPassword(data,type){
    if(type == 'show_password'){
      if(data){
        this.show_password = false
        return
      }else{
        this.show_password = true
        return
      }
    }

    if(type == 'show_confirm_password'){
      if(data){
        this.show_confirm_password = false
        return
      }else{
        this.show_confirm_password = true
        return
      }
    }
  }

  setShowPasswordAll(){
    this.show_password = false
    this.show_confirm_password  = false   
  }

}
