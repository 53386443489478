<app-header-desktop></app-header-desktop>

<ion-tabs>
  <ion-tab-bar slot="bottom" class="border-0 d-lg-none d-flex">
    <ion-tab-button routerLink="/home"  (click)="$event.stopPropagation();$event.preventDefault();goToPage('home')" class="bg-white" routerLinkActive="tab-selected">
      <ion-icon name="home-outline" class="font-tmt-20" routerLinkActive="d-none"></ion-icon>
      <ion-icon name="home" class="font-tmt-20 unactive" routerLinkActive="d-block"></ion-icon>
      <ion-label>หน้าหลัก</ion-label>
    </ion-tab-button>
    <ion-tab-button routerLink="/wishlists" (click)="$event.stopPropagation();$event.preventDefault();goToPage('wishlists')" class="bg-white" routerLinkActive="tab-selected">
      <ion-icon name="bookmark-outline" class="font-tmt-20" routerLinkActive="d-none"></ion-icon>
      <ion-icon name="bookmark" class="font-tmt-20 unactive" routerLinkActive="d-block"></ion-icon>
      <ion-label>{{role != 'organization'? 'งานที่สนใจ':'ผู้สมัครที่สนใจ'}}</ion-label>
    </ion-tab-button>
    <ion-tab-button routerLink="/booking" (click)="$event.stopPropagation();$event.preventDefault();goToPage('booking')" class="bg-white" routerLinkActive="tab-selected">
      <ion-icon name="people-outline" class="font-tmt-18" routerLinkActive="d-none"></ion-icon>
      <ion-icon name="people" class="font-tmt-20 unactive" routerLinkActive="d-block"></ion-icon>
      <ion-label>คำร้องขอสมัครงาน</ion-label>
    </ion-tab-button>
    <ion-tab-button routerLink="/notifications" (click)="$event.stopPropagation();$event.preventDefault();goToPage('notifications')" class="bg-white" routerLinkActive="tab-selected">
      <ion-icon name="notifications-outline" class="font-tmt-20" [ngClass]="{'bubble': unread_message > 0}" routerLinkActive="d-none"></ion-icon>
      <ion-icon name="notifications" class="font-tmt-20 unactive" [ngClass]="{'bubble': unread_message > 0}" routerLinkActive="d-block"></ion-icon>
      <ion-label>แจ้งเตือน</ion-label>
    </ion-tab-button>
    <ion-tab-button routerLink="/profile" (click)="$event.stopPropagation();$event.preventDefault();goToPage('profile')" class="bg-white" routerLinkActive="tab-selected">
      <ion-icon name="person-circle-outline" class="font-tmt-20" routerLinkActive="d-none"></ion-icon>
      <ion-icon name="person-circle" class="font-tmt-20 unactive" routerLinkActive="d-block"></ion-icon>
      <ion-label>โปรไฟล์</ion-label>
    </ion-tab-button>
  </ion-tab-bar>
</ion-tabs>