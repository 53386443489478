import { NavController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';
import { lastValueFrom, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { NotificationService } from '@app/services/notification.service';
import { appActionsActions } from '@app/store/app_actions';
import { IAuthorization } from '@app/types/IAuthorization';
import { AuthenService } from '@app/services/authen.service';

@Component({
  selector: 'app-app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss']
})
export class AppLayoutComponent implements OnInit {

  role = 'worker' as IAuthorization.RoleType

  constructor(
    private navController: NavController,
    private notificationService: NotificationService,
    private store: Store<any>,
    private authen: AuthenService
  ) { }

  pageList: string[] = [
    'home',
    'wishlists',
    'booking',
    'notifications',
    'profile',
  ]
  subscribeList = [] as {
    name: string;
    target: Subscription
  }[]
  unread_message = 0

  ngOnInit(): void {
    this.countUnreadMessage()
    this.subscribeList.push({
      name: "unread_message_count", 
      target: this.store.select("app_actions", "unread_message_count").subscribe((res: number) => {
        console.log("unread_message_count: ", res)
        this.unread_message = res
      })
    })
    let authen = this.authen.jwtDecode(this.authen.getAuthenToken())
    if(authen){
      this.role = authen.role
    }
  }

  goToPage(page: string) {
    let cruurent_path = location.pathname == '' || location.pathname == '/'?'/home':location.pathname;
    const index = this.pageList.indexOf(cruurent_path.split('/')[1]);
    if (index == -1) {
      this.navController.navigateRoot('/home');
    }
    else {
      // this.navController.navigateForward(this.pageList[index + 1]);
      let page_index = this.pageList.indexOf(page);
      if (page_index == -1) {
        this.navController.navigateRoot('/home');
      }
      else {
        // find back or forward from page_index
        if(page_index > index){
          this.navController.navigateForward(this.pageList[page_index]);
        }else{
          this.navController.navigateBack(this.pageList[page_index]);
        }
      }
    }
  }

  countUnreadMessage() {
    lastValueFrom(this.notificationService.getNotification()).then((notifications) => {
      let count_unread = 0
      for (const notification of notifications) {
        if (!notification.is_read) {
          count_unread++
        }
      }
      this.store.dispatch(appActionsActions.unreadMessageCount(count_unread))
    })
  }

}
