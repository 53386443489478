import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IWorker } from '@app/types/IWorker';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WorkersService {

  constructor(
    private http: HttpClient
  ) { }

  getProfile(): Observable<IWorker.WorkerProfile>{
    return this.http.get('/workers/profile') as Observable<any>
  }

  getProfileById(id: any): Observable<IWorker.Profile>{
    return this.http.get('/workers/byid/'+id) as Observable<any>
  }

  saveProfile(profile: IWorker.ProfileInput): Observable<IWorker.WorkerProfile>{
    return this.http.put('/workers/profile', profile) as Observable<IWorker.WorkerProfile>
  }

  getModel(): Observable<IWorker.ModelResponse>{
    return this.http.get('/workers/model-profile') as Observable<any>
  }

  updatePassword(password: IWorker.PasswordInput): Observable<any>{
    return this.http.put('/workers/password', password) as Observable<any>
  }

  getPositionModel(): Observable<IWorker.WorkerJobPositionModel>{
    return this.http.get('/workers/model-position') as Observable<IWorker.WorkerJobPositionModel>
  }

  getExperienceModel(): Observable<IWorker.WorkerJobExperienceModel>{
    return this.http.get('/workers/model-experience') as Observable<IWorker.WorkerJobExperienceModel>
  }

  //save-position
  savePosition(position: IWorker.WorkerJobPositionInput): Observable<IWorker.WorkerProfile>{
    return this.http.put('/workers/save-position', position) as Observable<any>
  }
  //save-experience
  saveExperience(experience: IWorker.WorkerJobExperienceInput): Observable<IWorker.WorkerProfile>{
    return this.http.put('/workers/save-experience', experience) as Observable<any>
  }
  // search
  search(model: IWorker.SearchModel): Observable<IWorker.SearchResult>{
    return this.http.post(`/workers/search`, model) as Observable<any>
  }
  // recommend search
  recommend(model: IWorker.RecommendModel): Observable<IWorker.RecommendResult>{
    return this.http.post(`/workers/recommend`, model) as Observable<any>
  }

  toggleEmail(): Observable<{
    res_code: number;
    message: string;
    email_notification: number
  }> {
    return this.http.put(`/workers/email-notification/toggle`, {}) as Observable<any>
  }

  getEducationsModel(): Observable<IWorker.WorkerEducationModel>{
    return this.http.get('/workers/model-educations') as Observable<IWorker.WorkerEducationModel>
  }

  saveEducations(model: IWorker.WorkerEducationInput): Observable<IWorker.WorkerProfile> {
    return this.http.put('/workers/save-educations', model) as Observable<IWorker.WorkerProfile>
  }

}
