<footer class="d-none d-lg-block">
  <section class="footer-luihub">
    <div class="container-lg py-3">
      <div class="row">
        <div class="col-xl col-lg-auto col-12 d-flex mb-xl-0 mb-3">
          <img src="/assets/images/logo-luihub-text.png" alt="Luihub" style="max-width: 120px;" class="img-fluid m-auto">
        </div>
        <div class="d-xl-none d-lg-block"></div>
        <div class="col-xl-3 col-lg-4 col-12">
          <div class="text-black-45 font-weight-500">ที่อยู่</div>
          <div class="text-black-8s5">
            26 Chit Lom Alley,<br>
            Khwaeng Lumphini, Pathum Wan, Bangkok 10330
          </div>
        </div>
        <div class="col-xl-3 col-lg-4 col-12">
          <div class="text-black-45 font-weight-500">เบอร์ติดต่อ</div>
          <div class="text-black-85">
            <a href="tel:+660627986987" class="alink-secondary-light">06-2798-6987</a> (คุณแซนด์ ผู้ดูแลบริษัท)
          </div>
          <div class="text-black-85">
            <a href="tel:+66992323459" class="alink-secondary-light">09-9232-3459</a> (คุณมุก ผู้ดูแลผู้หางาน)
          </div>
        </div>
        <div class="col-xl col-lg col-12">
          <div class="text-black-45 font-weight-500">อีเมล</div>
          <div>
            <a href="mailto:info@luihub.com" class="alink-secondary-light">info@luihub.com</a> 
          </div>
        </div>

        <div class="col-xl col-md col-12">
          <div class="text-black-45 font-weight-500">ช่วยเหลือ</div>
          <div>
            <a href="mailto:legal@myworkforce.io?subject=ติดต่อผู้ดูแลระบบ" class="alink-text-black-85">ติดต่อผู้ดูแลระบบ</a> 
          </div>
          <div>
            <a href="mailto:legal@myworkforce.io?subject=แจ้งลบบัญชีผู้ใช้งาน" class="alink-text-black-85">แจ้งลบบัญชีผู้ใช้งาน</a>
          </div>
        </div>
        
      </div>
    </div>
    <div class="d-flex justify-content-center border-top" >
      <div class="lh-label p-2">Copyright ® {{copyright}} <a href="https://www.luihub.com" target="_blank" class="alink-secondary">luihub.com</a> </div>
    </div>
  </section>
</footer>