import { workerProfileActions } from './store/worker_profile';
import { profileOrgActions } from './store/org_profile';
import { Store } from '@ngrx/store';
import { AuthenService } from '@app/services/authen.service';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { IWorker } from './types/IWorker';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'luihub';

  isLogin: boolean = false;
  isFirstLoad: boolean = true;

  subscribeList = [] as {
    name: string;
    target: Subscription
  }[]

  constructor(
    private authen: AuthenService,
    private router: Router,
    private store: Store<any>
  ) {

    let authen_data = this.authen.jwtDecode(this.authen.getAuthenToken())
    if(authen_data){
      this.isLogin = true;
      if(authen_data.role === 'organization'){
        this.store.dispatch(profileOrgActions.loadProfile())
      }else if (authen_data.role === 'worker'){
        this.store.dispatch(workerProfileActions.loadProfile())
      }
      
    }

    this.subscribeList.push({
      name: "profile_worker",
      target:  this.store.select("profile_worker").subscribe((res: IWorker.WorkerProfile) => {
        if (!res) {
          return
        }
        if (res.require_info && this.isFirstLoad) {
          this.isFirstLoad = false
          this.router.navigate(['/profile'])
        }
      })
    })
  }

  ngOnInit() {
    console.log('AppComponent.ngOnInit()');
  }

  ngAfterViewInit() {
    console.log('AppComponent.ngAfterViewInit()');
    navigator.geolocation.getCurrentPosition(async (position) => {
      console.log('position', position)
    }, ()=>{
      console.log('getCurrentPosition error')
    })
    // time out 1000ms
    setTimeout(() => {
      if(this.isLogin){
        try{
          document.getElementsByClassName('grecaptcha-badge')[0].remove()
        }catch{}
      }
    }, 1000);
  }


}
