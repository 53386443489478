
import {Sha256} from '@aws-crypto/sha256-browser'



export default class Helper {

    i2hex(i: any) {
      return ('0' + i.toString(16)).slice(-2);
    }

    async sha256(data: string) {
        const hash = new Sha256()
        hash.update(data)
        return Array.from(await hash.digest()).map(this.i2hex).join('');
    }

    passwordPattern(password: string) {
      return !(!/[A-Z]/g.test(password) || !/[a-z]/g.test(password) || !/[0-9]/g.test(password) || !/[!@#$%^&*(),.?":{}|<>]/g.test(password));
    }

}