import { createAction, createReducer, on } from "@ngrx/store";



export const appActionsActions = {
    fetchLastJobs: createAction('[App] Fetch Last Jobs'),
    wishlistLastUpdate: createAction('[App] Wishlist Update'),
    unreadMessageCount: createAction('[App] Unread Message Count', (count: number) => {
        return { count }
    }),
    fetchFilterJobs: createAction('[App] Fetch Filter Jobs')
}


export const appActionsState = {
    fetch_last_jobs: Date.now(),
    wishlist_last_update: Date.now(),
    unread_message_count: 0,
    fetch_filter_jobs: 0
}


const _appActionsReducer = createReducer(
    appActionsState,
    on(appActionsActions.fetchLastJobs, (state) => {
        return { ...state, fetch_last_jobs: Date.now() }
    }),
    on(appActionsActions.wishlistLastUpdate, (state) => {
        return { ...state, wishlist_last_update: Date.now() }
    }),
    on(appActionsActions.unreadMessageCount, (state, { count }) => {
        return { ...state, unread_message_count: count }
    }),
    on(appActionsActions.fetchFilterJobs, (state) => {
        return { ...state, fetch_filter_jobs: Date.now() }
    })
);

export function appActionsReducer(state: any, action: any) {
    return _appActionsReducer(state, action);
}