import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Params, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthenService } from '@app/services/authen.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthenGuard implements CanActivate, CanActivateChild {

  constructor(
    private router: Router,
    private authenService: AuthenService
  ) { }

  canActivate(route: ActivatedRouteSnapshot,state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.authenService.getAuthenToken()) {
      return true;
    }


    const queryParams: Params = {};
    if (route.queryParamMap.has("type")) {
      queryParams["type"] = route.queryParamMap.get("type");
    }

    const redirectPath = getRedirectPath(route, state);
    if (redirectPath) {
      queryParams["redirect_path"] = redirectPath;
    }

    this.router.navigate(["/sign-in"], {
      queryParams,
    });
    return false;
  }
  canActivateChild(childRoute: ActivatedRouteSnapshot,state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.authenService.getAuthenToken()) {
      return true;
    }

    const queryParams: Params = {};
    if (childRoute.queryParamMap.has("type")) {
      queryParams["type"] = childRoute.queryParamMap.get("type");
    }

    const redirectPath = getRedirectPath(childRoute, state);
    if (redirectPath) {
      queryParams["redirect_path"] = redirectPath;
    }

    this.router.navigate(["/sign-in"], {
      queryParams,
    });
    return false;
  }
  
}

function getRedirectPath(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
  if (route.queryParamMap.get("redirect") == "1") {
    return state.url;
  }

  if (document.referrer.includes(environment.cms_domain)) {
    return state.url;
  }

  return ""
}
