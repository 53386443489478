import { environment } from './../environments/environment';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { BehaviorSubject, catchError, filter, Observable, switchMap, take, throwError } from 'rxjs';
import { AuthenService } from './services/authen.service';
import { IAuthorization } from './types/IAuthorization';

@Injectable()
export class APIHttpInterceptor implements HttpInterceptor {

  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private auth: AuthenService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let header:any = {};
    if (this.auth.getAuthenToken()) {
      try{
        header['Authorization'] = `Bearer ${this.auth.getAuthenToken()}`
      }catch(e){}
    }
    let bash_api = `${environment.api_path}`
    if(request.url.includes('/api/v1')){
      bash_api = bash_api.replace('/api/jobs','')
    }
    let api_url = `${bash_api}${request.url}`
    const apiReq = request.clone({
      url: api_url,
      setHeaders: header
    });

    return next.handle(apiReq).pipe(catchError((error: HttpErrorResponse) =>{
      if (apiReq.url.includes("/authen/refresh")) {
        this.signOut()
        return throwError(() => error);
      }
      if (error.status !== 401) {
        return throwError(() => error);
      }

      if(!this.auth.getRefreshToken()){
        return throwError(() => error);
      }

      if (this.refreshTokenInProgress) {
        return this.refreshTokenSubject.pipe(
          filter(result => result !== null),
          take(1),
          switchMap(()=> next.handle(this.addAuthenticationToken(apiReq)))
        )
      }else{
        this.refreshTokenInProgress = true;
        this.refreshTokenSubject.next(null);
        return this.auth.refresh(this.auth.getRefreshToken()).pipe(switchMap((token: IAuthorization.ResponseToken)=>{
            localStorage.setItem(this.auth.KEY_NAME, JSON.stringify(token))
            this.refreshTokenInProgress = false;
            this.refreshTokenSubject.next(token);
            return next.handle(this.addAuthenticationToken(apiReq));
        }), catchError((err: any) => {
            this.refreshTokenInProgress = false;
            this.signOut()
            return throwError(() => err);
        }))
      }
    }))
  }

  addAuthenticationToken(request_add: HttpRequest<any>) {
    const accessToken = this.auth.getAuthenToken();
    if (!accessToken) {
        return request_add;
    }
    return request_add.clone({
        setHeaders: {
            Authorization: `Bearer ${this.auth.getAuthenToken()}`
        }
    });
  }

  signOut(){
    localStorage.removeItem(this.auth.KEY_NAME)
    location.reload()
  }

}
