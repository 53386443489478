import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderDesktopComponent } from './header-desktop.component';
import { RouterModule } from '@angular/router';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzButtonModule } from 'ng-zorro-antd/button';
@NgModule({
    declarations: [
        HeaderDesktopComponent
    ],
    exports: [
        HeaderDesktopComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        NzDropDownModule,
        NzMenuModule,
        NzAvatarModule,
        NzIconModule,
        NzPopoverModule,
        NzButtonModule
    ]
})
export class HeaderDesktopModule { }
