import { Component, OnInit } from '@angular/core';
import { IAuthorization } from '@app/types/IAuthorization';
import { AuthenService } from '@app/services/authen.service';
import { IWorker } from '@app/types/IWorker';
import { Subscription } from 'rxjs';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { Store } from '@ngrx/store';
import { NotificationService } from '@app/services/notification.service';
import { lastValueFrom } from 'rxjs';
import { appActionsActions } from '@app/store/app_actions';
import { INotification } from '@app/types/INotification';
import { Router } from '@angular/router';
@Component({
  selector: 'app-header-desktop',
  templateUrl: './header-desktop.component.html',
  styleUrls: ['./header-desktop.component.scss']
})
export class HeaderDesktopComponent implements OnInit {

  role = null
  
  profile = {
    name:null,
    img:null
  } 

  subscribeList = [] as {
    name: string;
    target: Subscription
  }[]

  notifications = [] as INotification.Notification[]
  unread_message = 0

  constructor(
    private store: Store<any>,
    private authen: AuthenService,
    private notificationService: NotificationService,
    private router: Router,
  ) { }

  async ngOnDestroy(): Promise<void> {
    this.subscribeList.forEach(sub=>{
      sub.target.unsubscribe()
    })
  }

  async ngOnInit(): Promise<void> {
    let authen = this.authen.jwtDecode(this.authen.getAuthenToken())
    if(authen){
      this.role = authen.role

      if(this.role == 'worker'){
        this.subscribeList.push({
          name: "profile",
          target: this.store.select("profile_worker").subscribe((res) => {
            if(!res) return
            this.profile.name = res.profile.name
            this.profile.img = res.profile.profile
            // console.log('get profile header ---',res)
          })
        })
      }
      else if(this.role == 'organization'){
        this.subscribeList.push({
          name: "profile",
          target: this.store.select("profile_org").subscribe(res => {
            if(!res) return
            this.profile.name = res.profile.name
            this.profile.img = res.profile.logo
            console.log('Get profile header ---',res)
          })
        })
      }

      this.onGet()
      console.log('Get Notifications :',this.notifications)
      this.countUnreadMessage()
      this.subscribeList.push({
        name: "unread_message_count", 
        target: this.store.select("app_actions", "unread_message_count").subscribe((res: number) => {
          console.log("unread_message_count: ", res)
          this.unread_message = res
        })
      })
    }

  }

  countUnreadMessage() {
    lastValueFrom(this.notificationService.getNotification()).then(res => {
      let count_unread = 0
      for (const notification of res) {
        if (!notification.is_read) {
          count_unread++
        }
      }
      this.store.dispatch(appActionsActions.unreadMessageCount(count_unread))
    })
  }

  onGet($event: any = null){
    lastValueFrom(this.notificationService.getNotification()).then( res => {
      this.notifications = res
    }).catch(err=>{
        if($event){
          $event.target.complete();
        }
    })
  }

  onClick(data: INotification.Notification){
    lastValueFrom(this.notificationService.markAsRead(data.id)).then(res=>{
      data['is_read'] = true
      this.onGet()
    }).catch(err=>{
    })
    this.router.navigate([`/booking/detail/${data.schedule_interview.schedule_no}`])
  }

  logOut(){
    localStorage.removeItem(this.authen.KEY_NAME)
    location.reload()
  }

}
